/**
 * App.js
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import './App.css';

// Navigation
function Nav() {
  return (
    <nav>
      <ul>
        <li><Link to="start" smooth={true} duration={500}>Start</Link></li>
        <li><Link to="projekte" smooth={true} duration={500}>Projekte</Link></li>
        <li><Link to="contact" smooth={true} duration={500}>Kontakt</Link></li>
      </ul>
    </nav>
  );
}

// Projektgalerie
function Projekte() {
  const [aktivesBild, setAktivesBild] = useState(0);
  const [bildKey, setBildKey] = useState(0); // Neuer Zustand für den Schlüssel
  const bilder = ['/images/bspBild1.jpeg', '/images/bspBild2.jpeg', '/images/bspBild3.jpeg', '/images/bspBild4.jpeg'];

  useEffect(() => {
    const timer = setInterval(() => {
      setAktivesBild(aktuellesBild => (aktuellesBild + 1) % bilder.length);
      setBildKey(prevKey => prevKey + 1); // Schlüssel aktualisieren
    }, 10000);

    return () => clearInterval(timer);
  }, [aktivesBild, bilder.length]);

  const wechselBild = (neuesBild) => {
    let aktualisiertesBild = neuesBild;
    if (neuesBild < 0) {
      aktualisiertesBild = bilder.length - 1;
    } else {
      aktualisiertesBild = neuesBild % bilder.length;
    }
    setAktivesBild(aktualisiertesBild);
    setBildKey(bildKey => bildKey + 1); // Schlüssel aktualisieren
  };

  return (
    <div id="projekte">
      <button className="pfeil links" onClick={() => wechselBild(aktivesBild - 1)}>{"<"}</button>
      <img
        key={bildKey} // Verwendung des Schlüssels
        src={bilder[aktivesBild]}
        alt={`Projekt ${aktivesBild + 1}`}
        className="aktiv"
      />
      <button className="pfeil rechts" onClick={() => wechselBild(aktivesBild + 1)}>{">"}</button>
      <div className="bild-container">
        {bilder.map((bild, index) => (
          <img
            key={index}
            src={bild}
            alt={`Projekt ${index + 1}`}
            onClick={() => wechselBild(index)}
            className={index === aktivesBild ? 'klein-aktiv' : ''}
          />
        ))}
      </div>
    </div>
  );
}



// Kontaktformular
function ContactForm() {
  return (
    <div id="contact">
      <form>
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="E-Mail" />
        <textarea placeholder="Nachricht"></textarea>
        <button type="submit">Senden</button>
      </form>
    </div>
  );
}

// Footer
function Footer() {
  const year = new Date().getFullYear(); // Aktuelles Jahr

  return (
    <footer className="footer">
      © {year} Cassian Sanzo
    </footer>
  );
}

// Haupt-App-Komponente
function App() {
  return (
    <div className="App">
      <Nav />
      <header className="App-header" id="start">
        <h1>Mein Name ist Cassian Sanzo</h1>
        <p>Ich bin ein motivierter IMS-Schüler an der Kantonsschule Sargans mit Fokus auf IT und Programmierung...</p>
        <p>Dies ist nur eine Webseite zum Testen!</p>
      </header>
      <Projekte />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
